import { defineStore } from 'pinia';

export function usePreloaderStore() {
  return defineStore('preloaderStore', () => {
    const showPreloader = ref<boolean>(false);

    function activatePreloader() {
      showPreloader.value = true;
    }

    function deactivatePreloader() {
      showPreloader.value = false;
    }

    return { showPreloader, activatePreloader, deactivatePreloader };
  })();
}